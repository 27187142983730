import "./bootstrap";

import { createRoot, hydrateRoot } from "react-dom/client";
import { createInertiaApp } from "@inertiajs/react";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { ToastProvider } from "./Contexts/ToastContext";

const appName = import.meta.env.VITE_APP_NAME || "InterlaceKit";

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.tsx`,
            import.meta.glob("./Pages/**/*.tsx")
        ),
    setup({ el, App, props }) {
        const AppWithToast = () => (
            <ToastProvider>
                <App {...props} />
            </ToastProvider>
        );

        if (import.meta.env.DEV) {
            createRoot(el).render(<AppWithToast />);
            return;
        }

        hydrateRoot(el, <AppWithToast />);
    },
    progress: {
        color: "#ffc107",
    },
});
