import axios from 'axios';
import _ from 'lodash';
import * as Sentry from "@sentry/react";

if (import.meta.env.PROD) {
    Sentry.init({
        dsn: "https://ed34224dc7b923976e87ad7736b207b3@o1305002.ingest.us.sentry.io/4508074379706368",
        integrations: [],
    });
} else {
    console.log('Sentry disabled in development environment');
}

window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

window._ = _;


window.addEventListener('vite:preloadError', (event) => {
    window.location.reload()
})
