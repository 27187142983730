import React, { createContext, useState, useContext, ReactNode } from "react";
import { Toast, ToastContainer } from "react-bootstrap";

type ToastType = "info" | "success" | "warning" | "danger";

interface ToastMessage {
    id: number;
    message: string;
    type: ToastType;
    autohide: boolean;
    delay: number;
}

interface ToastContextType {
    addToast: (
        message: string,
        type?: ToastType,
        autohide?: boolean,
        delay?: number
    ) => void;
    removeToast: (id: number) => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

interface ToastProviderProps {
    children: ReactNode;
}

export const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
    const [toasts, setToasts] = useState<ToastMessage[]>([]);

    const addToast = (
        message: string,
        type: ToastType = "info",
        autohide: boolean = true,
        delay: number = 5000
    ) => {
        const id = Date.now();
        setToasts((prevToasts) => [
            ...prevToasts,
            { id, message, type, autohide, delay },
        ]);
    };

    const removeToast = (id: number) => {
        setToasts((prevToasts) =>
            prevToasts.filter((toast) => toast.id !== id)
        );
    };

    const caclBgClass = (type: ToastType) => {
        switch (type) {
            case "info":
                return "text-bg-info";
            case "success":
                return "text-bg-success";
            case "warning":
                return "text-bg-warning";
            case "danger":
                return "text-bg-danger";
            default:
                return "";
        }
    };

    return (
        <ToastContext.Provider value={{ addToast, removeToast }}>
            {children}
            <ToastContainer
                className="position-fixed bottom-0 end-0 p-5"
                position="top-center"
            >
                {toasts.map((toast) => (
                    <Toast
                        key={toast.id}
                        onClose={() => removeToast(toast.id)}
                        show={true}
                        autohide={toast.autohide}
                        delay={toast.delay}
                        className={caclBgClass(toast.type)}
                    >
                        <div className="d-flex">
                            <Toast.Body>{toast.message}</Toast.Body>
                            <button
                                type="button"
                                className="btn-close me-2 m-auto"
                                onClick={() => removeToast(toast.id)}
                                aria-label="Close"
                            ></button>
                        </div>
                    </Toast>
                ))}
            </ToastContainer>
        </ToastContext.Provider>
    );
};

export const useToast = (): ToastContextType => {
    const context = useContext(ToastContext);
    if (!context) {
        throw new Error("useToast must be used within a ToastProvider");
    }
    return context;
};
